import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/shahid/Desktop/Projects/Personal/madrasatulilm.com/src/views/layouts/MainLayout.js";
import { Helmet } from 'react-helmet';
import { Box, Breadcrumb, Divider, SEO, Text, BookList, Button, Video } from '../../../views/components';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO pageTitle="আরবী বর্ণ চিনি ও আরবী পড়তে শিখি (কায়দা মাক্কিয়াহ'র প্রেসেন্টেশন)" mdxType="SEO" />
    <Box maxWidth={960} margin="0 auto" padding={{
      xs: 3,
      sm: 4
    }} mdxType="Box">
      <Text variant="h2" textAlign="center" mdxType="Text">
  আরবী বর্ণ চিনি ও আরবী পড়তে শিখি (কায়দা মাক্কিয়াহ'র প্রেসেন্টেশন)
      </Text>
      <Divider mdxType="Divider" />
      <Breadcrumb links={[{
        url: '/',
        name: 'নীড়পাতা'
      }, {
        url: '/study-materials/',
        name: 'শিক্ষাপোকরণ'
      }, {
        url: '/study-materials/arabic',
        name: 'আরবী'
      }]} mdxType="Breadcrumb" />
      <Divider mdxType="Divider" />
      <p>{`কুরআন পড়তে শেখাতে যে বইটি শুরুতেই শিশুর হাতে তুলে দিতে হয়, তা হচ্ছে, ‘কায়দা বই’।‘মাদ্রাসাতুল ইল্‌ম’ এক্ষেত্রে ‘কায়দা মাক্কিয়াহ’ নামক কায়দা বইটি ব্যবহার করে থাকে। ‘মাদানী ছাপার মুসহাফ’টি পড়তে এই কায়দা বইটি বিশেষভাবে উপযোগী। এই পেইজ থেকে ‘কায়দা মাক্কিয়াহ’ বইটি এবং এর প্রতিটি অধ্যায়ের উপর নির্মিত প্রেসেন্টেশন ডাউনলোড করতে পারেন।`}</p>
      <p>{`তাছাড়াও, নিচের ভিডিও’র প্লে-লিস্টে রয়েছে শিশুদের কায়দা পড়ানোর বিভিন্ন টেকনিক সম্বলিত বেশ কিছু ভিডিও।`}</p>
      <Video videoSrcURL="https://www.youtube.com/embed/videoseries?list=PLN6GIGge5un8mSh1HcZzXU2CogpV8TGTM" videoTitle="আরবী বর্ণ, তাদের ৪ টি রূপ ও ছবিসহ শব্দ (উচ্চারণ সহ)" frameWidth="720px" frameHeight="504px" width="100%" mb={4} mdxType="Video" />
      <Box textAlign="center" mb={4} mdxType="Box">
  <a href="https://drive.google.com/file/d/1-cPC2JCAX1sGlhf5hrKu8ta7DAW0QVX8/view?usp=sharing" target="_blank" rel="noreferrer noopener">
    <Button variant="primary" mdxType="Button">কায়দা মাক্কিয়াহ বই ডাউনলোড</Button>
  </a>
      </Box>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://drive.google.com/drive/folders/1o9QynIp5nBSovdQDvtTcvvQ1VPeATC-q?usp=sharing"
          }}>{`অধ্যায়-১. আরবী হরফ বিষয়ক প্রেসেন্টেশন ডাউনলোড`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://drive.google.com/drive/folders/1Vsn2lHYo_N19xaS2x9uhlTlGnxxvweBY?usp=sharing"
          }}>{`অধ্যায়-২. যবর/ফাতহাহ বিষয়ক প্রেসেন্টেশন ডাউনলোড`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://drive.google.com/drive/folders/1i6YGG7tQYoY_mQOuyxv0BMnI0vl7ufj8?usp=sharing"
          }}>{`অধ্যায়-৩. যের/কাসরাহ বিষয়ক প্রেসেন্টেশন ডাউনলোড`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://drive.google.com/drive/folders/1EIWJphZQ0X2WK21BwQ3sH-K-g7PAAKC_?usp=sharing"
          }}>{`অধ্যায়-৪. পেশ/দাম্মাহ বিষয়ক প্রেসেন্টেশন ডাউনলোড`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://drive.google.com/drive/folders/1xQrqqpJk8Oj6gNeu9IgLlCpoRtu_JjCt?usp=sharing"
          }}>{`অধ্যায়-৫. জযম/সাকিন বিষয়ক প্রেসেন্টেশন ডাউনলোড`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://drive.google.com/drive/folders/1i-OsmGJCrI1GEMy2GMFWRjw7Kgzvy3tk?usp=sharing"
          }}>{`অধ্যায়-৬. মাদ্দি বিল আলিফ বিষয়ক প্রেসেন্টেশন ডাউনলোড`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://drive.google.com/drive/folders/1cDjVvXejZ0Mls1-iUjjZ6SrZfQkGSqWB?usp=sharing"
          }}>{`অধ্যায়-৭. মাদ্দু বিল ইয়া বিষয়ক প্রেসেন্টেশন ডাউনলোড`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://drive.google.com/drive/folders/1aZj72cQoatNq8SS9e0TtoH5iXieYkCJO?usp=sharing"
          }}>{`অধ্যায়-৮. মাদ্দু বিল ওয়াও বিষয়ক প্রেসেন্টেশন ডাউনলোড`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://drive.google.com/drive/folders/1LsmT1SJUVtDjhdQAnWrve0vOveWhfZVU?usp=sharing"
          }}>{`অধ্যায়-৯. তানউইন বিষয়ক প্রেসেন্টেশন ডাউনলোড`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://drive.google.com/drive/folders/1M8w1ijO3aWw76tbqdi8ZO0-SokMtgqlq?usp=sharing"
          }}>{`অধ্যায়-১০. তাশদীদ বিষয়ক প্রেসেন্টেশন ডাউনলোড`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://drive.google.com/drive/folders/1zIvgbhBJRsdZ5fHP5p1vrSVuGrqexnN_?usp=sharing"
          }}>{`অধ্যায়-১১. তাশদীদ+তানউইন বিষয়ক প্রেসেন্টেশন ডাউনলোড`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://drive.google.com/drive/folders/1QyGYfcLecNBTtGPT2V6kBcb2BgaWHXcA?usp=sharing"
          }}>{`অধ্যায়-১২. তাশদীদ+মাদ বিষয়ক প্রেসেন্টেশন ডাউনলোড`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://drive.google.com/drive/folders/1r6CDVXwcUr3M5-xhPHkWi_jlcwwqhtRM?usp=sharing"
          }}>{`অধ্যায়-১৩. লামু আল কামরিয়া ও শামসিয়া বিষয়ক প্রেসেন্টেশন ডাউনলোড`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://drive.google.com/drive/folders/1y2eOX-bH2Wx9BqE3lcFVcWgrCBKv8TDV?usp=sharing"
          }}>{`অধ্যায়-১৪. লাফাযুল জাল্লাতি বিষয়ক প্রেসেন্টেশন ডাউনলোড`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://drive.google.com/drive/folders/1LUy9JYyqRpg2bmWCPb3D25a9Gq2yk5DB?usp=sharing"
          }}>{`অধ্যায়-১৫. হামযাতুল ওয়াসল বিষয়ক প্রেসেন্টেশন ডাউনলোড`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://drive.google.com/drive/folders/1uUogGISWbgYQUs54uAc75XyMOTXb_pv0?usp=sharing"
          }}>{`অধ্যায় ১৬. ইদগাম বিষয়ক প্রেসেন্টেশন ডাউনলোড`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://drive.google.com/drive/folders/11EZuoO7a7S59Pm0PBxI-bot_vptFsh04?usp=sharing"
          }}>{`অধ্যায় ১৭. ইযহার বিষয়ক প্রেসেন্টেশন ডাউনলোড`}</a></li>
      </ul>
      <h3 {...{
        "id": "মাদীনা-মুসহাফ-এবং-মাজিদী-বা-ইন্দো-পাক-মুসহাফের-মধ্যে-পার্থক্য",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h3" {...{
          "href": "#%E0%A6%AE%E0%A6%BE%E0%A6%A6%E0%A7%80%E0%A6%A8%E0%A6%BE-%E0%A6%AE%E0%A7%81%E0%A6%B8%E0%A6%B9%E0%A6%BE%E0%A6%AB-%E0%A6%8F%E0%A6%AC%E0%A6%82-%E0%A6%AE%E0%A6%BE%E0%A6%9C%E0%A6%BF%E0%A6%A6%E0%A7%80-%E0%A6%AC%E0%A6%BE-%E0%A6%87%E0%A6%A8%E0%A7%8D%E0%A6%A6%E0%A7%8B-%E0%A6%AA%E0%A6%BE%E0%A6%95-%E0%A6%AE%E0%A7%81%E0%A6%B8%E0%A6%B9%E0%A6%BE%E0%A6%AB%E0%A7%87%E0%A6%B0-%E0%A6%AE%E0%A6%A7%E0%A7%8D%E0%A6%AF%E0%A7%87-%E0%A6%AA%E0%A6%BE%E0%A6%B0%E0%A7%8D%E0%A6%A5%E0%A6%95%E0%A7%8D%E0%A6%AF",
          "aria-label": "মাদীনা মুসহাফ এবং মাজিদী বা ইন্দো পাক মুসহাফের মধ্যে পার্থক্য permalink",
          "className": "header-anchor-icon before"
        }}><svg parentName="a" {...{
            "version": "1.1",
            "x": "0px",
            "y": "0px",
            "viewBox": "0 0 162.656 162.656",
            "xmlSpace": "preserve",
            "width": "0.6em",
            "height": "0.6em",
            "fill": "#2b9348",
            "stroke": "#2b9348",
            "strokeWidth": "0"
          }}><path parentName="svg" {...{
              "d": "M151.764,10.894c-14.522-14.522-38.152-14.525-52.676-0.008l0.003,0.003L76.112,33.872l10.607,10.605l22.983-22.988 l-0.002-0.002c8.678-8.663,22.785-8.658,31.457,0.014c8.673,8.672,8.672,22.786,0,31.461l-34.486,34.484 c-4.201,4.202-9.787,6.516-15.729,6.516c-5.942,0-11.529-2.314-15.73-6.516L64.605,98.052c7.035,7.035,16.389,10.91,26.338,10.91 c9.949,0,19.303-3.875,26.335-10.91l34.487-34.484C166.284,49.043,166.284,25.413,151.764,10.894z"
            }}></path>{` `}<path parentName="svg" {...{
              "d": "M52.96,141.162L52.96,141.162c-8.675,8.67-22.788,8.668-31.461-0.005c-8.673-8.675-8.673-22.791-0.001-31.465L55.98,75.21 c8.675-8.674,22.789-8.674,31.462,0L98.05,64.604c-14.524-14.523-38.154-14.524-52.676,0L10.89,99.086 c-14.519,14.523-14.519,38.154,0.001,52.678c7.263,7.262,16.801,10.893,26.341,10.892c9.536,0,19.074-3.629,26.333-10.887 l0.002-0.001l22.984-22.99l-10.608-10.606L52.96,141.162z"
            }}></path>{` `}</svg></a><a parentName="h3" {...{
          "href": "https://drive.google.com/file/d/1zzFpAJRjGw97DsmvEyfLt8D_cYGEVWuK/view?usp=sharing"
        }}>{`মাদীনা মুসহাফ এবং মাজিদী বা ইন্দো-পাক মুসহাফের মধ্যে পার্থক্য`}</a></h3>
    </Box>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      